<div class="u-textarea-interactive" [ngClass]="{ focused: isFocus }">
  <ng-container *ngIf="leftSideContent" [ngTemplateOutlet]="leftSideContent"></ng-container>
  <u-textarea
    [rows]="rows"
    [cols]="cols"
    [placeholder]="placeholder"
    [styles]="styles"
    [disabled]="disabled"
    [maxLength]="maxLength"
    [maxWords]="maxWords"
    [ngClass]="{ 'disable-auto-resize': disableAutoResize }"
    [model]="model"
    (onChange)="onChangeEvent($event)"
    (onFocus)="onFocusEvent($event)"
    (onBlur)="onBlurEvent($event)"
    (onEnter)="onEnterEvent($event)"
    (handleValidationResult)="handleMaxContentLengthEvent($event)"
    [readonly]="readonly"
    [autoResize]="autoResize"
    [autofocus]="autofocus"
    [maxRowLimit]="maxRowLimit"
    [minHeight]="minHeight"
    pInputTextarea
    class="inline-textarea"
    name="uTextareaInteractive"
    ngDefaultControl
    #textareaInteractive
  ></u-textarea>
  <u-button
    *ngIf="inlineExtraIcon"
    class="inline-extra-icon"
    type="secondary"
    [fontIcon]="inlineExtraIcon"
    (click)="onInlineExtraIconClickEvent($event)"
    [disabled]="inlineExtraIconDisabled"
    [tooltipDisabled]="inlineExtraIconDisabled"
    [pTooltip]="inlineExtraIconTooltip"
    [onlyIcon]="true"
    [styles]="extraIconStyles"
    tooltipPosition="left"
    tooltipStyleClass="u-tooltip"
  ></u-button>
  <ng-container *ngIf="footerTemplate" [ngTemplateOutlet]="footerTemplate"></ng-container>
</div>
