<textarea
  [rows]="rows"
  [cols]="cols"
  [placeholder]="placeholder"
  [style]="styles"
  [disabled]="disabled"
  [attr.maxLength]="maxLength || null"
  [ngClass]="{
    'disable-auto-resize': disableAutoResize,
    truncate: model?.length && !isTitleFocused && truncateLines,
    'max-resize-with-scroll': autoResize && !doResize,
    'fixed-background-color': fixedBackgroundColor,
  }"
  [(ngModel)]="model"
  (ngModelChange)="onChangeEvent($event)"
  (focus)="onFocusEvent($event)"
  (blur)="onBlurEvent($event)"
  (keydown.enter)="onEnterEvent($event)"
  [readonly]="readonly"
  [autoResize]="doResize"
  pInputTextarea
  class="u-textarea"
  name="uTextarea"
  ngDefaultControl
  #textarea
></textarea>
